import { lazy } from 'react';

// Core
export const PaginationWrapper = lazy(() => import('./PaginationWrapper'));
export const OfflinePageRenderer = lazy(() => import('./OfflinePageRenderer'));

// Core
export { default as RenderCoreComponents } from './RenderCoreComponents';
export { default as DynamicDataLooper } from './DynamicDataLooper';
export { default as DynamicContentLooper } from './DynamicContentLooper';
export { default as ApiRequestComponent } from './ApiRequestComponent';
export { default as AppTop } from './AppTop';
export { default as AppFooter } from './AppFooter';
