import { useMemo } from 'react';
import { MODULE_NAME } from './constants';
import {
  useIsActive as useIsActiveModules,
  useSettings as useSettingsModules
} from '../../hooks';
import { dataInsightSchema } from './schema';

export const useIsActive = () => useIsActiveModules(MODULE_NAME);
export const useSettings = () => useSettingsModules(MODULE_NAME);

export const useConfig = () => {
  const settings = useSettings() ?? {};
  const config = useMemo(() => dataInsightSchema.parse(settings || {}), []);
  return config;
};
