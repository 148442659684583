import React from 'react';
import PropTypes from 'prop-types';
import { componentsPropType, vmPropTypes } from '../../global-prop-types';
import ComponentsArrRenderer from './ComponentsArrRenderer';
import useAPIDataHook, {
  getPropsMask
} from '../../global-hooks/useAPIDataHook';
import { Loading } from '../global';

/** For the admin interface - please make sure that the mask - will be different than other existings !! */

/**
 * This component will make an API and will add it's content to the existing apiData
 * an exmpale of structure of apiDataInfo -  can be found on the pages level
 *
 *
    [
      {
        "mask": "sub_rooms_info",
        "url": "/v1/rooms/$(urlParams.room_id).json",
        "body": {
          "event_id": 123,
          "include_sponsors": 1,
          "sort_by": "sort"
        },
        "refreshRate": 300000
      }
    ]

    make sure your component has:
      "replaceableRequestsProps": ["room_id"],

    Now all you need is to make sure your component gets room_id as a prop

 */

const ApiRequestComponent = (props) => {
  const {
    components,
    parent,
    componentData,
    apiDataInfo,
    replaceableRequestsProps,
    ...restProps // passed down the tree but also to useApiDataHook
  } = props;
  const { apiData = {}, urlParams, urlSearchParams } = restProps;

  const fakeUrlParams = {};
  replaceableRequestsProps?.forEach((entry) => {
    const { [entry]: propsEntry } = props;
    fakeUrlParams[entry] = propsEntry;
  });

  const tmp = Object.values(fakeUrlParams).filter((val) => val === undefined);
  if (tmp?.length !== 0) {
    // This means - our prop - that we are dependent on - is not there yet.
    // TODO: make loading better
    return 'Loading...';
  }

  // Only after we have our desired props - we move it forward
  // const newApiData = useAPIDataHook(apiDataInfo, props);
  const newApiData = useAPIDataHook(
    apiDataInfo,
    { ...urlParams, ...fakeUrlParams }, // urlParams
    urlSearchParams,
    { dataBank: { props: restProps } } // give access to props
  );
  if (
    apiDataInfo?.some(
      (apiEntry) => !newApiData[getPropsMask(apiEntry)]?.isReady
    )
  ) {
    // here - means - our api response is still not ready
    // TODO: make loading better
    return <Loading />;
  }

  return (
    <ComponentsArrRenderer
      parent={[...parent, 'ApiRequestComponent']}
      components={components}
      {...restProps} // This restProps contains apiData but without the newApiData.
      apiData={{ ...apiData, ...newApiData }} // overwrite apiData from restProps
    />
  );
};

ApiRequestComponent.propTypes = {
  parent: PropTypes.array,
  apiData: PropTypes.shape({}).isRequired,

  componentData: PropTypes.array,
  components: componentsPropType,

  apiDataInfo: PropTypes.array.isRequired,
  replaceableRequestsProps: PropTypes.array, // This will do replace to the API requests
  urlParams: PropTypes.shape({}),
  urlSearchParams: PropTypes.shape({})
};
ApiRequestComponent.defaultProps = {
  components: []
};

ApiRequestComponent.vmPropTypes = {
  components: vmPropTypes.components,
  apiDataInfo: vmPropTypes.apiContentRequest
};

export default ApiRequestComponent;
