import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { MatomoProvider } from '@datapunt/matomo-tracker-react';
import store from './global-state/redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import SeqLogger from './global-utils/seqLogger';
import { getInstance as getMatomoInstance } from './modules/matomo';
import chunkLoadErrorHandler from './modules/chunkLoadErrorHandler';
// src: https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis

chunkLoadErrorHandler();
const container = document.getElementById('vmRevolutionRoot');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <Provider store={store}>
    <MatomoProvider value={getMatomoInstance()}>
      <App />
    </MatomoProvider>
  </Provider>
);

reportWebVitals((item) => {
  const duration = (
    Math.round(item.name === 'CLS' ? item.delta * 1000 : item.delta) / 1000
  ).toFixed(2);
  const message = '{name} logged with {duration}s';
  SeqLogger.emit({
    messageTemplate: message,
    level: 'Information',
    properties: {
      name: item.name,
      duration
    }
  });
});
