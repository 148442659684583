import { MODULE_NAME } from './constants';
import {
  useIsActive as useIsActiveModules,
  useSettings as useSettingsModules
} from '../../hooks';
import { isInsideAppContainer } from '../../../global-utils/vmFunctions/others';
import { useIsPreview } from '../../../global-utils/preview';
import { checkRedirectToHolding } from './utils';
import { useIsUserLoggedIn } from '../../../global-utils';

export const useIsActive = () => useIsActiveModules(MODULE_NAME);
export const useSettings = () => useSettingsModules(MODULE_NAME);

export const useCheckRedirectToHolding = () => {
  const settings = useSettings() ?? {};
  const isPreview = useIsPreview();
  const isAppContainer = isInsideAppContainer();
  const isLoggedIn = useIsUserLoggedIn();

  return checkRedirectToHolding({
    ...settings,
    isPreview,
    isAppContainer,
    isLoggedIn
  });
};
