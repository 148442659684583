export const getPoliciesForResource = (
  resourceType,
  resourceId,
  accessMatrix
) => {
  const getResource = () => {
    const foundResources = accessMatrix.resources.filter((r) => {
      if (Array.isArray(r.Identifier)) {
        return (
          r.Type?.toLowerCase() === resourceType?.toLowerCase()
          && r.Identifier?.some(
            (x) => x.toLowerCase() === resourceId?.toLowerCase()
          )
        );
      }
      return (
        r.Type?.toLowerCase() === resourceType?.toLowerCase()
        && r.Identifier?.toLowerCase() === resourceId?.toLowerCase()
      );
    });

    if (foundResources.length > 1) {
      // DEBUG: for now just print a WARNING
      console.warn(
        `WARNING: multiple resource access definitions for Resource:${resourceType}id:${resourceId}`
      );
    }
    if (foundResources.length > 0) {
      return foundResources[0];
    }
    return null;
  };

  const foundResource = getResource();
  if (!foundResource) return [];

  const returnedPolicies = [];

  foundResource.PoliciesIds.forEach((policyId) => {
    const foundPolicy = accessMatrix.policies.find((p) => p.id === policyId);
    if (foundPolicy) returnedPolicies.push(foundPolicy);
  });

  return returnedPolicies;
};

export const getDenyAllowPoliciesForResource = (
  resourceType,
  resourceId,
  accessMatrix
) => {
  const denyAllowRules = {
    deny: [],
    allow: []
  };
  const foundPolicies = getPoliciesForResource(
    resourceType,
    resourceId,
    accessMatrix
  );

  for (const policy of foundPolicies) {
    if (policy.Effect === 'Allow') {
      denyAllowRules.allow.push(policy);
    }
    if (policy.Effect === 'Deny') {
      denyAllowRules.deny.push(policy);
    }
  }

  return denyAllowRules;
};

export const handleIncludes = (param) => {
  const {
    userField,
    requirement,
    accessDenyReasons,
    evaluatedPermissionsArray
  } = param;
  if (!requirement.Values.includes(userField)) {
    accessDenyReasons.push(requirement.PrincipalField);
  }
  evaluatedPermissionsArray.push(requirement.Values.includes(userField));
};

export const handleHcp = (
  user,
  requirement,
  accessDenyReasons,
  evaluatedPermissionsArray
) => {
  const hcpVal = requirement.Values?.[0];
  let elEval = false;
  // @ts-ignore
  if (hcpVal === null || hcpVal === true) {
    if (user.hcp) {
      elEval = true;
    }
    // @ts-ignore
  } else if (hcpVal === false) {
    if (user.hcp === false) {
      elEval = true;
    }
  }
  if (!elEval) {
    accessDenyReasons.push(requirement.PrincipalField);
  }
  evaluatedPermissionsArray.push(elEval);
};

export const handleLoggedIn = (
  user,
  requirement,
  accessDenyReasons,
  evaluatedPermissionsArray
) => {
  let loggedIn = false;
  const lLoggedInVal = requirement.Values?.[0] === 'true';
  if (lLoggedInVal === null || lLoggedInVal === true) {
    if (user.loggedIn) {
      loggedIn = true;
    }
  } else if (lLoggedInVal === false) {
    if (user.loggedIn === false) {
      loggedIn = true;
    }
  }
  if (!loggedIn) {
    accessDenyReasons.push(requirement.PrincipalField);
  }
  evaluatedPermissionsArray.push(loggedIn);
};

export const handleTicketCondition = (params) => {
  const {
    userTickets,
    conditionFn,
    requirement,
    accessDenyReasons,
    evaluatedPermissionsArray
  } = params;
  let valid = false;
  for (const ticket of userTickets) {
    if (conditionFn(ticket)) {
      valid = true;
      break;
    }
  }
  if (!valid) {
    accessDenyReasons.push(requirement.PrincipalField);
  }
  evaluatedPermissionsArray.push(valid);
};

export const composeUser = (ticket) => {
  const {
    EnableHCP,
    ParticipantTypeName,
    ParticipantTypeValid,
    SocietyMemberType,
    Country
  } = ticket ?? {};

  return {
    hcp: EnableHCP ?? false,
    participantType: ParticipantTypeName ?? '',
    participantTypeValid: ParticipantTypeValid ?? false,
    societyMemberType: SocietyMemberType ?? '',
    country: Country ?? '',
    tickets: ticket ? [ticket] : [],
    todayDate: null,
    loggedIn: false
  };
};
