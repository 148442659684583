/**
 * START OF SERVICE WORKER
 */
// const getArrayOfAllImportsFromSettings = (settings) => {
//   const imports = [];
//   Object.keys(settings.modules).forEach((key) => {
//     if (settings.modules[key].imports) {
//       imports.push(...settings.modules[key].imports);
//     }
//   });
//   return imports.map((entry) => entry.path);
// };
export const sendMessageToServiceWorker = (msg) => {
  // To send messages to the service worker, the SW must be READY
  navigator.serviceWorker?.ready
    ?.then((registration) => {
      // console.log('[APP SW] is ready');
      // 2. Zugriff auf aktiven SW erhalten
      if (registration.active) {
        // 3. Nachricht senden
        registration.active.postMessage({
          msg: 'I AM APP SENDING A MESSAGE',
          ...msg
        });
      } else {
        // console.log("[APP SW] not active", registration)
      }
    })
    .catch((err) => {
      console.log('[APP SW] is not ready', err);
    });
};

export const startServiceWorker = (settings) => (dispatch, getStore) => {
  const {
    isIframe,
  } = getStore().appState;

  // if it's an iframe, or offline usage are disabled
  if (isIframe || !settings?.offlineUsage?.isActive) {
    // if there should not be a service worker
    // https://love2dev.com/blog/how-to-uninstall-a-service-worker/#:~:text=On%20the%20right%2Dhand%20side,will%20unregister%20the%20service%20worker.
    // removing the service worker
    navigator.serviceWorker?.getRegistrations()?.then((registrations) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const registration of registrations) {
        registration.unregister();
      }
    });

    return;
  }
  // const importList = getArrayOfAllImportsFromSettings(settings);
  const importList = []; // I shut down these imports.. no need for any of them offline..

  // console.log('importList', importList);
  // now register a service worker

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('message', (evt) => {
      switch (evt.data.msg) {
        case 'SW_ACTIVATED':
          // console.log('[APP SW]SW_ACTIVATED');
          sendMessageToServiceWorker({
            msg: 'APP_IMPORTLIST',
            payload: importList
          });
          break;
        // case 'SW_FAVS_RECIEVED':
        // alert("WOHOOOOOOO")
        // dispatch(setOfflineReady())
        default:
          console.log('[APP SW]Got message - default case', evt?.data);
      }
    });

    // sendMessageToServiceWorker({msg: 'PAGE_SW_START'})
    navigator.serviceWorker
      .register(`${window.location.origin}/service1.js`)
      .then((registration) => {
        if (registration.installing) {
          // if it returns a serviceWorker - it means it's installing - Reload the page!!
          // window.setTimeout(() => {
          //   window.location.reload(true);
          // }, 500);
        }
        registration.update();
      });
  } else {
    console.warn("This browser doesn't support service workers sw");
  }
};

/**
 * END OF SERVICE WORKER
 */
