import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setClientData } from '../../global-state/redux/actions';
import { componentsPropType, stylesPropType, vmPropTypes } from '../../global-prop-types';
import { IconComp } from '../Layout';
import vmFunctions from '../../global-utils/vmFunctions';
import { setWindowTimer, getWindowTimer } from '../../global-utils/vmFunctions/dateTime';
import useShallowEqualSelector from '../core/useShallowEqualSelector';
import { mapData } from '../../global-utils/dataMapping';
import ComponentsArrRenderer from '../core/ComponentsArrRenderer';

const JmeSlider = (props) => {
  const {
    sliderId,
    variant,
    components,
    side,
    top,
    width,
    styles,
    stylesIn,
    stylesOut,
    doClose,
    ignoredClassName,
    showCloseBtn,
    ...restProps
  } = props;
  const isOpen = useSelector((store) => store.appState.clientData[sliderId]);
  const dispatch = useDispatch();
  const menuBoxRef = useRef();
  const store = useShallowEqualSelector((state) => state);
  const dataBank = { vmFunctions, props, store };

  const handleCloseClick = () => {
    dispatch(setClientData(sliderId, false));
  };

  const closeSlider = () => {
    doClose.forEach((dataMap) => {
      const val = mapData(dataBank, dataMap);
      if (val?.func?.isReduxAction) {
        dispatch(val.func(...val.args));
      }
    });
  };

  const checkIfClickedOutside = (e) => {
    if (
      isOpen
      && menuBoxRef.current
      && !(menuBoxRef.current.parentNode.contains(e.target) || (!!ignoredClassName.length && (e.target.className.indexOf(ignoredClassName) > 0 - 1)))
      && Date.now() - getWindowTimer('sliderTimestamp') > 500
    ) {
      closeSlider();
    }
  };

  useEffect(() => {
    window.addEventListener('popstate', () => {
      closeSlider();
    });

    if (isOpen) {
      setWindowTimer('sliderTimestamp');
      document.addEventListener('click', checkIfClickedOutside);
    }

    return () => {
      document.removeEventListener('click', checkIfClickedOutside);
      window.removeEventListener('popstate', () => {
        closeSlider();
      });
    };
  }, [isOpen]);

  const getDynamicStyles = (moveFrom) => {
    // TODO: there is no need to check condition here if we slide from top/bottom/left
    // if we use stylesIn and stylesOut for any slide-direction.
    // This way, the responsibility to place the in/out component is moved to the person who configures.
    // Why we move to stylesIn/stylesOut:
    // 1. opacity 0-1 is not alwyas the case. For example search panel has opacity ~0.8
    // 2. top,left,right,bottom values can be arbitrary: for menu we slide only by width and then make menu transparent
    //   For search panel we can not just make panel transparent, coz then it will overlay on the appTop
    // 3. Sometimes, you want to hide by slide, sometimes by shinking height
    // 4. we also need to have option to do inverted logic. For example the element by default
    //   is out (isOpen ? opacity => 1 : opacity => 0) as it is for advertisements.
    // 5. PS the jme-slider-wrapper has to be wrapped in one more div, coz padding of the jme-slider-weapper
    //   prevent it from shrinking height to 0px.
    if (moveFrom === 'top' || moveFrom === 'bottom') {
      return {
        ...styles?.wrapper, // styles from settgins go first, since we need to overwrite them
        ...(isOpen ? stylesOut?.wrapper : stylesIn?.wrapper)
      };
    }
    // we keep moveFrom === 'left' as default
    return {
      pointerEvents: isOpen ? 'auto' : 'none',
      [moveFrom]: isOpen ? 0 : `-${width}`,
      top,
      opacity: isOpen ? 1 : 0,
      width,
      ...styles?.wrapper
    };
  };

  return (
    <div
      className={`jme-slider-wrapper ${isOpen ? 'jme-slider-wrapper-open' : ''} ${variant || ''}`}
      style={getDynamicStyles(side)}
      ref={menuBoxRef}
    >
      {showCloseBtn && (
        <button
          type="button"
          onClick={handleCloseClick}
          style={{
            color: 'white',
            background: 'transparent',
            border: 'unset',
            ...styles?.button
          }}
        >
          <IconComp
            icon={{ iconType: 'mui', name: 'Close' }}
            styles={{ wrapper: styles?.icon }}
          />
        </button>
      )}
      <ComponentsArrRenderer components={components} {...restProps} />
    </div>
  );
};

JmeSlider.defaultProps = {
  variant: '',
  ignoredClassName: '',
  styles: {
    wrapper: {},
    button: {},
    icon: {}
  },
  stylesIn: {},
  stylesOut: {},
  sliderId: '',
  components: [],
  side: 'left',
  top: '0px',
  width: '500px',
  doClose: [],
  showCloseBtn: true
};

JmeSlider.propTypes = {
  styles: stylesPropType,
  ignoredClassName: PropTypes.string,
  stylesIn: stylesPropType,
  stylesOut: stylesPropType,
  sliderId: PropTypes.string,
  variant: PropTypes.string,
  width: PropTypes.string,
  top: PropTypes.string,
  side: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  components: componentsPropType,
  doClose: PropTypes.array,
  showCloseBtn: PropTypes.bool
};

JmeSlider.vmPropTypes = {
  styles: {
    wrapper: vmPropTypes.styles,
    button: vmPropTypes.styles,
    icon: vmPropTypes.styles
  },
  ignoredClassName: vmPropTypes.string,
  sliderId: vmPropTypes.string,
  variant: vmPropTypes.string,
  width: vmPropTypes.string,
  top: vmPropTypes.string,
  side: vmPropTypes.string,
  components: vmPropTypes.components,
  doClose: vmPropTypes.array,
  showCloseBtn: vmPropTypes.boolean
};

export default JmeSlider;
