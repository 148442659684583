import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectStatus } from '../model/slice';
import { accessControlPage } from '../model/access-control';
import { composeUser } from '../model/utils';
import { useSettings } from '../../hooks';
import { MODULE_NAME, NO_ACCESS_URL } from '../model/constants';
import { useGetPage } from '../model/hooks';
import { getAccessMatrix, getTicket } from '../model/state';
import {
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_READY,
  STATUS_UNINITIALIZED
} from '../../constants';

const AccessControlWrapper = (props) => {
  const {
    renderElement, renderLoading, renderError, page
  } = props;
  const { accessControl } = page ?? {};
  const { identifier } = accessControl ?? {};
  const status = useSelector(selectStatus) ?? STATUS_UNINITIALIZED;
  const ticket = getTicket();
  const accessMatrix = getAccessMatrix();
  const getPage = useGetPage();
  const { noAccessPages = [] } = useSettings(MODULE_NAME);

  if (status === STATUS_UNINITIALIZED) return renderLoading();
  if (status === STATUS_LOADING) return renderLoading();
  if (status === STATUS_ERROR) return renderError();

  const { result: hasAccess, cause } = accessControlPage(
    identifier,
    accessMatrix,
    composeUser(ticket)
  );

  // console.info('accessControl', {
  //   hasAccess,
  //   cause,
  //   identifier,
  //   ticket,
  //   accessMatrix
  // });

  if (status === STATUS_READY && hasAccess) return renderElement(page);

  const { pathname = NO_ACCESS_URL } = noAccessPages.find(({ condition }) => new RegExp(condition).test(cause));
  return renderElement(getPage(pathname));
};

AccessControlWrapper.propTypes = {
  renderElement: PropTypes.func,
  renderLoading: PropTypes.func,
  renderError: PropTypes.func,
  page: PropTypes.shape({})
};

export default AccessControlWrapper;
