import { useLocation } from 'react-router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Loading } from '../components';
import storageAndCookie from '../global-utils/storage-and-cookies';
import { logoutUser } from '../global-state/redux/actions';

const LogoutPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const prevLoacation = storageAndCookie.get('previousLocation') || '/';
  const params = new URLSearchParams(location.search);
  const externalUrl = params.get('externalUrl');
  const decodedExternalUrl = externalUrl
    ? decodeURIComponent(externalUrl)
    : null;

  console.log(
    'emc2 LogoutPage a',
    Date.now(),
    decodedExternalUrl,
    document.cookie
  );

  useEffect(() => {
    function deleteAllCookies() {
      const cookies = document.cookie.split(';');

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
        console.log('emc2 LogoutPage deleting', JSON.stringify({ cookie, name, c: document.cookie }));
      }
    }

    deleteAllCookies();
    localStorage.clear();
    dispatch(logoutUser());

    console.log(
      'emc2 LogoutPage b',
      Date.now(),
      document.cookie
    );
    if (decodedExternalUrl) {
      window.location.href = decodedExternalUrl;
    } else {
      window.location = prevLoacation;
    }
    // We susspect, that the page reloads before the storage and the cookies are cleared.
    // If this is true, then the new page load would see the session user as logged in.
    // This timeout is supposed to postpone the reload to give time for storage and cookies to be cleared.
    // setTimeout(() => {
    //   console.log(
    //     'emc2 LogoutPage b',
    //     Date.now(),
    //     decodedExternalUrl,
    //     document.cookie
    //   );
    //   if (decodedExternalUrl) {
    //     window.location.href = decodedExternalUrl;
    //   } else {
    //     window.location = prevLoacation;
    //   }
    // }, 1000);
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 2
      }}
    >
      <Loading />
    </div>
  );
};

export default LogoutPage;
