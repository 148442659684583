import { holdingPageConfigSchema } from './schema';

export const checkRedirectToHolding = (config) => (urlStr) => {
  const {
    enableForLoggeInUser,
    enableInAppContainer,
    enableInPreview,
    guardedUrl,
    holdingPageUrl,
    isAppContainer,
    isLoggedIn,
    isPreview
  } = holdingPageConfigSchema.parse(config);

  const guardedUrlRe = new RegExp(guardedUrl);
  const holdingPageUrlRe = new RegExp(holdingPageUrl);

  if (isPreview && !enableInPreview) return false;
  if (isAppContainer && !enableInAppContainer) return false;
  if (isLoggedIn && !enableForLoggeInUser) return false;
  if (holdingPageUrlRe.test(urlStr)) {
    return false;
  }

  return guardedUrlRe.test(urlStr);
};
