import { useEffect } from 'react';
import { useLoginInfo } from '../../../global-utils';
import { useConfig, useIsActive } from '../model/hooks';
import { dataInsightInit, extendUser } from '../model/utils';
import { setConfig } from '../model/state';
import {
  getAccessToken,
  useIsActive as useIsManageConfigActive,
  useConfig as useManageConfig
} from '../../manage-config';
import { apiExtendUserDetails } from '../api/api';
import { fetchTicket } from '../../access-control/api/api';

const Init = () => {
  const accessToken = getAccessToken();
  const isActive = useIsActive();
  const manageConfig = useManageConfig();
  const isManageConfigActive = useIsManageConfigActive();
  const moduleConfig = useConfig();
  const userLoginInfo = useLoginInfo();

  useEffect(() => {
    const canInit = Boolean(
      !window.preventIframeTracking // preview in iFrame
        && isActive // module is active
        && moduleConfig.authKey // must have authKey
        && window.DataInsightTracking // module js files finished loading
    );

    if (canInit) {
      setConfig(moduleConfig);
      const promises = [];
      if (accessToken && isManageConfigActive) promises.push(fetchTicket({ accessToken, ...manageConfig }));
      if (isManageConfigActive) promises.push(apiExtendUserDetails(manageConfig));

      if (accessToken) {
        Promise.all(promises)
          .then(extendUser(userLoginInfo))
          .then(dataInsightInit);
      } else {
        dataInsightInit();
      }
    }
  }, [userLoginInfo, isActive, moduleConfig, manageConfig]);

  return null;
};

export default Init;
