import hash from 'object-hash';
import debounce from 'debounce';
import { trackEventSchema } from './schema';
import { getConfig } from './state';
import { isInsideAppContainer } from '../../../global-utils/vmFunctions/others';
import { isArr } from '../../../global-utils';

/* tracks is a map of the debounced functions per `detail` object.
 keys are hashed detail objects (important that befor hash they are sorted)
 values are debounced-dispaTchtrack functions https://www.npmjs.com/package/debounce.
 Once there is a request to track event, we save debounced-dispatchTrack to Map
 After debounceInterval, dispatchTrack gets called and then the value
 for is clearde from Map. */
const tracks = new Map();

const dispatchTrack = (h, detail) => () => {
  const ev = new CustomEvent('data-insight.track', { detail });
  window.dispatchEvent(ev);
  tracks.delete(h);
};

export const dataInsightTrack = (params) => {
  const config = getConfig();
  if (window.preventIframeTracking) return;
  if (!window.DataInsightTracking?.trackingListener) return;
  if (config === null) return; // Module was not initialized

  // detail - set of properties passed to tracking event
  const { debounceInterval = config.debounceInterval, ...detail } = params;
  const { success, data } = trackEventSchema.safeParse(detail);

  if (!success) {
    throw new Error('data-insight: wrong parametrization', {
      cause: detail
    });
  }

  const h = hash(detail);
  if (!tracks.has(h)) {
    tracks.set(h, debounce(dispatchTrack(h, data), debounceInterval));
  }

  tracks.get(h)();
};

export const trackPage = (params) => dataInsightTrack({ sourceApplicationAction: 'vmx-page', ...params });
export const trackPageProgram = (params) => dataInsightTrack({ sourceApplicationAction: 'vmx-page-program', ...params });
export const trackPageProfile = (params) => dataInsightTrack({ sourceApplicationAction: 'vmx-page-profile', ...params });

export const dataInsightInit = (user = {}) => {
  const { authKey: dataInsightAuthKey } = getConfig();
  const detail = {
    dataInsightAuthKey,
    user: {
      ...user,
      EventDevice: isInsideAppContainer() ? 'vmx-mobile-app' : null
    }
  };

  const ev = new CustomEvent('data-insight.init', { detail });
  window.dispatchEvent(ev);
};

// @pArr - array of responses of API calls
export const extendUser = (userLoginInfo = {}) => (pArr) => {
  // @reduce - combine API resp objects into single obj
  const resp = pArr.reduce((acc, el) => ({ ...acc, ...el }), {});

  const { user = {}, manage_api_user: manageApiUser = {} } = userLoginInfo;
  const { id: UserId } = manageApiUser;
  const { city: UserCity, country: UserCountryCode } = user;

  return {
    ...resp,
    UserIsHcp: resp.HealthCareProfessional,
    UserAreaOfInterest: isArr(resp.SelectedInterests)
      ? resp.SelectedInterests.join('|')
      : null,
    UserGender: resp.GenderExtended,
    UserProfession: resp.Profession,
    UserVisitorType: resp.ParticipantTypeName,
    UserId,
    UserCity,
    UserCountryCode
  };
};
