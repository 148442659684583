import store from '../../../../global-state/redux';
import { PIN_BOARD_ID_KEY, PIN_BOARD_KEY } from './pinboardConstants';
import { setMyNetworking } from '../../../../global-state/redux/actions';
import { getPinboard } from './pinboardSelectors';

const { dispatch } = store;

export const setPinboard = (pinboard) => {
  dispatch(setMyNetworking(PIN_BOARD_KEY, pinboard));
};

export const setPinboardId = (pinboardId) => {
  dispatch(setMyNetworking(PIN_BOARD_ID_KEY, pinboardId));
};

export const addNewPinBoardPost = (newPost) => {
  const pinboardPosts = getPinboard();
  setPinboard([newPost, ...pinboardPosts]);
};
