let ticket = [];
let accessMatrix = null;

export const setTicket = (t) => {
  ticket = t;
};
export const getTicket = () => ticket;

export const setAccessMatrix = (ac) => {
  accessMatrix = ac;
};
export const getAccessMatrix = () => accessMatrix;
