import PropTypes from 'prop-types';
import { Suspense, useEffect, useState } from 'react';
import {
  useRoutes,
  useNavigate,
  useLocation,
  Navigate as Redirect
} from 'react-router-dom';
import Navbar from './header/Navbar';
import HeadBanner from './header/HeadBanner';
import DynamicPageRenderer from './core/DynamicPageRenderer';
import TestFlightPage from '../pages/TestFlightPage';
import OcaPage from '../pages/OcaPage';
import LogoutPage from '../pages/LogoutPage';
import AnyErrorBoundary from './global/AnyErrorBoundary';
import { Loading } from './global';
import useShallowEqualSelector from './core/useShallowEqualSelector';
import useTheme from '../global-hooks/useTheme';
import storageAndCookie from '../global-utils/storage-and-cookies';
import Staging from './Others/Staging';
import ComponentsArrRenderer from './core/ComponentsArrRenderer';
import PageRenderer from './core/PageRenderer';
import { vmRefProcessorApp } from '../global-utils';
import { setVmFunctionNavigate } from '../global-utils/vmFunctions/navigate';
import {
  AccessControlWrapper,
  useIsAccessControlActive
} from '../modules/access-control';
import { AppFooter, AppTop } from './core';

export const handleHardReload = async (url) => {
  await fetch(url, {
    headers: {
      Pragma: 'no-cache',
      Expires: '-1',
      'Cache-Control': 'no-cache'
    }
  });
  window.location.href = url;
  // This is to ensure reload with url's having '#'
  window.location.reload();
};

const Temp404 = (props) => {
  const { notFoundPage } = useShallowEqualSelector(
    (state) => state.appState.settings
  );
  if (
    notFoundPage
    && notFoundPage.isActive
    && notFoundPage.components?.length
  ) {
    // if exists, active and has componentsArray
    return (
      <PageRenderer
        {...props}
        title={notFoundPage.title}
        pageName={notFoundPage.title}
        components={notFoundPage.components}
      />
    );
  }
  return '!404! TEMP 404 Page not found';
};

const AppWrapper = (props) => {
  const { appContainer } = useTheme();
  const { isAuthLoading, core } = useShallowEqualSelector(
    (state) => state.appState
  );
  const isAccessControlActive = useIsAccessControlActive();
  const { event_id: eventId } = core;
  // TODO: remove appName. It's legacy, being used on Kokapo, ectrims.
  const {
    appName,
    appHeader,
    appHeadBanner,
    appSettings = {},
    pages,
    autoRedirect
  } = useShallowEqualSelector((state) => state.appState.settings);
  const { appTitle, version: appVersion = null, disableDefaultLayoutRestrictions } = appSettings;
  const { title: pageTitle } = useShallowEqualSelector(
    (state) => state.appState.currentPage
  );
  const { currentAppUrl, testFlightPageInfo, testFlightComponentsBuilding } = useShallowEqualSelector((state) => state.appState);
  const navigate = useNavigate();
  const { pathname: currentPathname } = useLocation();
  const [lastPathname, setLastPathname] = useState(currentPathname);
  const ifScrollToTop = lastPathname !== currentPathname;
  const isStaging = storageAndCookie.get(`JmeENV_${eventId}`);

  // Export navigate from useNavigate to varaible stored in vmFunctions.navigate
  // This gives possibility to redirect from within vmFunctions.
  useEffect(() => {
    setVmFunctionNavigate(navigate);
  }, [navigate]);

  useEffect(() => {
    if (testFlightPageInfo) {
      const gotoPage = `${currentAppUrl}/test-flight-page`;
      // console.log('TEST FLIGHT IS ON ! GO TO', gotoPage);
      navigate(gotoPage);
    }
  }, [testFlightPageInfo]);

  useEffect(() => {
    document.title = pageTitle
      ? `${appTitle || appName} | ${pageTitle}`
      : appTitle || appName;
  }, [appTitle, appName, pageTitle]);

  useEffect(() => setLastPathname(currentPathname), [currentPathname]);
  useEffect(() => {
    const isLeftAppBefore = storageAndCookie.get(
      'lastLocationBeforeLeavingApp'
    );
    if (isLeftAppBefore) {
      navigate(isLeftAppBefore);
      storageAndCookie.del('lastLocationBeforeLeavingApp');
    }
    // check for last version
    const currentVmVersionString = `vmRevolution_${eventId}_version`;
    // if key is not exists, getStorage return null instead of expected undefined
    // this is why appVersion assigned by default to null
    const lastVersion = storageAndCookie.get(currentVmVersionString);

    if (appVersion && lastVersion !== appVersion) {
      // we check for appVersion because it might be not exists and we will be in infinite loop
      // value exists but not the same as app version, make reload
      storageAndCookie.set(currentVmVersionString, appVersion);
      handleHardReload(window.location.href);
    }
  }, []);

  const dynamicRouting = pages.map((page) => {
    if (!page) return { path: '3030' };
    return {
      path: currentAppUrl + page.route.path,
      exact: page.route.exact,
      element: isAccessControlActive ? (
        <AccessControlWrapper
          page={page}
          renderLoading={() => <Loading />}
          renderError={() => 'Loading access error!'}
          renderElement={(pageProps) => (
            <DynamicPageRenderer key={page.id} {...props} {...pageProps} />
          )}
        />
      ) : (
        <DynamicPageRenderer key={page.id} {...props} {...page} />
      )
    };
  });

  const autoRedirectPages = (() => {
    if (!autoRedirect || !autoRedirect?.isActive || !autoRedirect?.urls?.length) return [];

    return autoRedirect.urls
      .filter((links) => links.fromUrl !== links.toUrl)
      .map((relinkPage) => ({
        path: relinkPage.fromUrl,
        exact: true,
        element: <Redirect to={relinkPage.toUrl} replace />
      }));
  })();

  let headBannerRouting;
  if (appHeadBanner?.isActive) {
    headBannerRouting = useRoutes(
      appHeadBanner?.routes.map((headBanner) => {
        if (headBanner) {
          const { route, ...rest } = headBanner;
          return {
            path: currentAppUrl + route.path,
            exact: route.exact || true,
            element: <HeadBanner {...props} {...rest} />
          };
        }
        return { path: '#' };
      })
    );
  }

  const RoutingElement = useRoutes([
    ...autoRedirectPages,
    ...dynamicRouting,
    {
      path: `${currentAppUrl}/test-flight-page`,
      element: <TestFlightPage {...props} />
    },
    { path: `${currentAppUrl}/oca/:uid/:accessToken`, element: <OcaPage /> },
    { path: `${currentAppUrl}/logout`, element: <LogoutPage /> },
    { path: '*', element: <Temp404 {...props} /> }
  ]);
  if (ifScrollToTop) window.scrollTo(0, 0);
  if (testFlightComponentsBuilding) {
    // this is only for building in admin
    // console.log(
    //   'GOT THIS: testFlightComponentsBuilding',
    //   testFlightComponentsBuilding
    // );
    return (
      <>
        <Suspense fallback={<Loading />}>
          <ComponentsArrRenderer
            {...props}
            components={vmRefProcessorApp.resolve(testFlightComponentsBuilding)}
          />
        </Suspense>
      </>
    );
  }
  return (
    <>
      {isStaging && <Staging />}
      {appHeader?.isActive && <Navbar {...props} {...appHeader} />}
      <AppTop {...props} />
      {appHeadBanner?.isActive && headBannerRouting}
      <div
        className={`${
          disableDefaultLayoutRestrictions ? '' : 'container-fluid appWrapper-wrapper'
        } ${
          appContainer?.wrapper?.variant || ''
        }`}
        style={appContainer?.styles?.wrapper}
      >
        <div
          className={`${
            disableDefaultLayoutRestrictions ? '' : 'container jme-container p-0'
          } ${
            appContainer?.content?.variant || ''
          }`}
          style={appContainer?.styles?.content}
        >
          <AnyErrorBoundary>
            {isAuthLoading ? <Loading /> : RoutingElement}
          </AnyErrorBoundary>
        </div>
      </div>
      <AppFooter {...props} />
    </>
  );
};

AppWrapper.propTypes = {
  allComps: PropTypes.shape({})
};
export default AppWrapper;
