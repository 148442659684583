import { z } from 'zod';

export const holdingPageConfigSchema = z.object({
  enableForLoggeInUser: z.boolean().default(false),
  enableInAppContainer: z.boolean().default(false),
  enableInPreview: z.boolean().default(false),
  guardedUrl: z.string().default('.*'),
  holdingPageUrl: z.string().default('/holding'),
  isAppContainer: z.boolean().default(false),
  isLoggedIn: z.boolean().default(false),
  isPreview: z.boolean().default(false)
});
