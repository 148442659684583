// TODO: search for isReduxAction across the code and move it to vmFunctionMetaData!
// TODO: move setClientData to relevant module!
import { getArrayVmTypeOf } from '../../global-prop-types/vmTypes';
import { vmTypes } from '../../global-prop-types';
import { setClientData } from '../../global-state/redux/actions';
import { getValueByVmPath } from '../vmPath';
import store from '../../global-state/redux';
import * as vmApi from './vmApi';
import * as components from './components';
import * as array from './vmArray';
import * as dateTime from './dateTime';
import * as others from './others';
import * as generateLinks from './generateLinks';
import * as vmCookies from './vmCookies';
import * as vmStorage from './vmStorage';
import * as clientData from './clientData';
import * as vmUrlSearchParams from './vmUrlSearchParams';
import * as styles from './styles';
import * as loggedInUser from './loggedInUser';
import storageAndCookie from '../storage-and-cookies';
import * as string from './vmString';
import * as windowLocation from './windowLocation';
import * as window from './window';
import * as object from './vmObject';
import { isNumber } from '../globalUtils';
import * as myNetworking from './myNetworking';
import * as core from './core';
import * as loginInfo from './loginInfo';
import * as navigate from './navigate';
import * as ws from './ws';
import * as favourite from './favourite';
import { vmFunctions as userProfileData } from '../../modules/user-profile-data';
import * as fileTypes from './file-types';
import { vmFunctions as accessControl } from '../../modules/access-control';
import { vmFunctions as dataInsightTracking } from '../../modules/data-insight';
import { vmFunctions as holdingPage } from '../../modules/holding-page';
import { vmFunctions as manageConfig } from '../../modules/manage-config';
import * as encode from './encode';

export * from './vmFunctionUtils';

const { getState } = store;

const vmFunctions = {
  log: {
    log: (msg) => console.log(msg)
  },
  breakpoint: {
    getCurrentBreakPoint: () => getState().appState?.currentBreakpoint,
    isSmallScreen: (dataObj) => () => dataObj.store.appState.currentBreakpoint === 'sm'
      || dataObj.store.appState.currentBreakpoint === 'xs',
    breakpointCase: (dataObj) => (caseValues) => {
      const { currentBreakpoint } = dataObj.store.appState;
      return caseValues?.[currentBreakpoint];
    },
    isCurrentBreakPointLessEqualTo: (testBreakPointStr) => {
      // example: currentBreakpoint: md and testBreakPointStr = md, then we return 2<=2 => true
      // example: currentBreakpoint: sm and testBreakPointStr = md, then we return 1<=2 => true
      // example: currentBreakpoint: lg and testBreakPointStr = sm, then we return 3<=1 => false
      const { currentBreakpoint } = getState().appState;
      const breakPoints = ['xs', 'sm', 'md', 'lg', 'xl'];
      const currentBreakPointIdx = breakPoints.findIndex(
        (el) => el === currentBreakpoint
      );
      const testBreakPointIdx = breakPoints.findIndex(
        (el) => el === testBreakPointStr
      );
      return currentBreakPointIdx <= testBreakPointIdx;
    }
  },
  json: {
    parse: (val) => JSON.parse(val),
    stringify: (val) => JSON.stringify(val)
  },
  number: {
    isNumber: (a) => isNumber(a),
    isGreather: (a, b) => a > b,
    isSmaller: (a, b) => a < b,
    add: (a, b) => a + b,
    subtract: (a, b) => a - b,
    toString: (val, radix) => val.toString(radix),
    toWord: (a) => {
      const numberAsWord = [
        'zero',
        'one',
        'two',
        'three',
        'four',
        'five',
        'six',
        'seven',
        'eight',
        'nine',
        'ten',
        'eleven',
        'twelve'
      ];
      return isNumber && a >= 0 ? numberAsWord[a] : '';
    },
    roundToUnits: (num) => {
      let tmp = Number(num);
      if (Number.isNaN(tmp)) return num;
      let suffix = '';
      if (tmp > 999) {
        tmp /= 1000;
        suffix = 'k';
      }
      if (tmp > 999) {
        tmp /= 1000;
        suffix = 'M';
      }
      if (tmp > 999) {
        tmp /= 1000;
        suffix = 'G';
      }
      return `${Math.round(tmp)}${suffix}`;
    },
    roundToMax: (num, max) => (num > max ? `+${max}` : num),
    toNumber: (str) => Number(str)
  },
  logic: {
    boolean: (val) => !!val,
    negate: (x) => !x,
    equals: (a, b) => a === b,
    notEquals: (a, b) => a !== b,
    isGreaterEqual: (a, b) => a >= b,
    ifGreaterThan: (a, b, trueVal, falseVal) => (a > b ? trueVal : falseVal),
    vmOrCondition: (a, b) => a ?? b,
    vmIfCondition: (condition, trueRes, falseRes) => (condition ? trueRes : falseRes),
    vmIfConditionEqualTo: (condition, equalTo, trueRes, falseRes) => (condition === equalTo ? trueRes : falseRes),
    vmIfBlocksCondition: (falseRes, trueRes, ...allConditions) => (allConditions?.every((el) => !!el) ? trueRes : falseRes)
  },
  filters: {
    filterByVmPath: (arr = [], vmPath = []) => arr.filter((el) => !!getValueByVmPath(el, vmPath)),
    filterSponsorsImagesByPath: (arr, ...path) => arr?.filter((el) => {
      const parsedSettings = JSON.parse(el.settings);
      const myRes = path.reduce((acc, next) => acc[next], parsedSettings);
      return !!myRes;
    })
  },
  // TODO: Ksawery This clientdata function is duplicated in clientData module. It should be factored out from here :)
  // ... some day
  setClientData: (key, data) => setClientData(key, data),
  // We need to move the setCookie to vmCookies.setCookie!!!
  setCookie: (name, days) => () => storageAndCookie.set(
    name,
    `${window.location.pathname}${window.location.search}`,
    days
  ),
  filterBasedOnWord: (word, word2) => word !== word2,
  customVmApi: {
    filterCurrentResource: (arr = [], currentId) => arr.filter((el) => el?.id !== currentId),
    filterCurrentResourceFromResourcegroups: (
      resourcegroups = [],
      currentId
    ) => {
      // Filtering current resource from resourcegroups and resourcetype_title === sound_presentation
      // (for presentation of the session - presentation no access)
      const filtered = [];
      resourcegroups.map((resourcegroup) => resourcegroup?.body?.resources?.filter((resource) => {
        if (
          resource?.id !== currentId
            && resource.resourcetype_title === 'sound_presentation'
        ) {
          filtered.push(resource);
        }
        return null;
      }));
      return filtered?.length === 0 ? null : filtered;
    }
  },
  accessControl,
  array,
  clientData,
  components,
  core,
  dataInsightTracking,
  dateTime,
  encode,
  favourite,
  fileTypes,
  generateLinks,
  holdingPage,
  loggedInUser,
  loginInfo,
  manageConfig,
  myNetworking,
  navigate,
  object,
  others,
  string,
  styles,
  userProfileData,
  vmApi,
  vmCookies,
  vmStorage,
  vmUrlSearchParams,
  window,
  windowLocation,
  ws
};
vmFunctions.breakpoint.getCurrentBreakPoint.vmFunctionMetaData = {
  returnVmType: vmTypes.string
};
vmFunctions.breakpoint.isSmallScreen.vmFunctionMetaData = {
  usesDataObj: true,
  returnVmType: vmTypes.boolean
};
vmFunctions.setClientData.isReduxAction = true;

vmFunctions.breakpoint.breakpointCase.vmFunctionMetaData = {
  usesDataObj: true,
  argsInfo: [{ uiTitle: 'Case object', vmType: vmTypes.object }],
  returnVmType: vmTypes.any
};

vmFunctions.array.some.vmFunctionMetaData = {
  returnVmType: vmTypes.boolean,
  argsInfo: [{ uiTitle: 'Input Array', vmType: getArrayVmTypeOf(vmTypes.any) }]
};

vmFunctions.window.location.vmFunctionMetaData = {
  returnVmType: vmTypes.string
};

vmFunctions.logic.vmIfConditionEqualTo.vmFunctionMetaData = {
  argsInfo: [
    { uiTitle: 'condition', vmType: { type: vmTypes.any } },
    { uiTitle: 'equalTo', vmType: { type: vmTypes.any } },
    { uiTitle: 'trueRes', vmType: { type: vmTypes.any } },
    { uiTitle: 'falseRes', vmType: { type: vmTypes.any } }
  ]
};

export default vmFunctions;
