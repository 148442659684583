import { getEventId } from '../../core';
import { parsePost } from '../groupposts/grouppostUtils';
import { myNetworkingApiCall } from '../myNetworkingApiUtils';
import { coerceToObj } from '../myNetworkingUtils';
import { COMMUNITIES_PATH, NEWS_PATH, PIN_BOARD_COMMENT_TYPE } from './pinboardConstants';
import { setPinboard, setPinboardId } from './pinboardOperations';
import { getPinboardId } from './pinboardSelectors';

const STORE_MASK_GROUPPOSTS_GET_PIN_BOARD = 'myNetworking/grouposts/get/pinBoard';

export const apiGetPinboardPosts = (props) => {
  const { comment, ...restProps } = coerceToObj(props);

  const body = {
    event_id: getEventId(),
    news_id: getPinboardId(),
    commenttype: PIN_BOARD_COMMENT_TYPE,
    use_cache: 0,
    include_children: 1
  };

  return myNetworkingApiCall({
    method: 'GET',
    storeMask: STORE_MASK_GROUPPOSTS_GET_PIN_BOARD,
    pathname: COMMUNITIES_PATH,
    body,
    ...restProps
  }).then((res) => {
    setPinboard(res.data?.map((el) => parsePost(el)));
  });
};

export const apiGetPinBoardId = () => {
  const body = {
    title: 'Pin board', // should not be changed
    event_id: getEventId()
  };
  return myNetworkingApiCall({
    method: 'GET',
    pathname: NEWS_PATH,
    body
  }).then((res) => {
    setPinboardId(res?.data?.[0]?.id);
    apiGetPinboardPosts();
  });
};
